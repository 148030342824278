@import './../../../styles/variables'

.link
  position: relative
  color: $dark

  &::after, &::before
    transition: width .3s ease-out
    content: ''
    width: 50%
    height: 2px
    position: absolute
    bottom: 0px
    background-color: $dark

  &::after
    left: 50%

  &::before
    right: 50%
  
  &:hover
    &::after, &::before
      transition: width .3s ease-in
      width: 0%
