@import './../../../styles/variables'

$height: 59px

.button
  position: relative
  height: $height
  width: 136px
  cursor: pointer

  &__content
    transition: border-color .5s ease, color .5s ease
    position: relative
    display: block
    width: 100%
    height: 100%
    border: $light 2px solid
    background-color: transparent
    font-size: 18px
    line-height: $height
    text-align: center
    color: $light
    z-index: 1

  &__background
    transition: opacity .5s ease
    position: absolute
    top: 6px
    left: 6px
    width: 100%
    height: 100%
    background: $primary-gradient
    z-index: 0
    opacity: 1
  
  &:hover
    .button__content
      transition: all .5s ease
      border-color: $primary
      color: $primary

    .button__background
      transition: opacity .5s ease
      opacity: 0
