@import './../../../styles/variables'

.elements
  padding: 0 9vw
  margin-bottom: 200px

  &__item
    display: flex
    padding-top: 220px

    &--reverse
      flex-direction: row-reverse

      .elements__title
        margin-left: auto
        margin-right: -158px
        z-index: 2

      .elements__description
        padding-left: 0
        padding-right: 100px

  &__image
    width: 36vw

  &__description
    padding-left: 100px
    padding-right: 35px

  &__title
    top: 60px
    margin-left: -158px
    align-items: flex-start

  &__text
    margin-top: 30px
  
  &__link
    margin-top: 40px
  
  @media only screen and (max-width: $phone)
    padding: 80px 30px 0
    margin-bottom: 25px

    &__item
      margin-bottom: 80px
      flex-direction: column
      padding-top: 0px

      &--reverse
        .elements__title
          margin-right: 0
          margin-left: 0
          align-self: flex-start
      
        .elements__image
          align-self: flex-end

        .elements__description
          padding-right: 0
    
    &__image
      width: 260px
    
    &__description
      margin-top: -23px
      padding-left: 0
      padding-right: 0
  
    &__title
      top: inherit
      margin-left: 0
      right: 0
      align-self: flex-end
