@import './../../../styles/variables'

.project
  position: relative
  overflow: hidden
  padding-bottom: 100px

  &::before, &::after
    content: ''
    position: absolute
    top: calc(33% - 100px)
    width: 30vw
    height: 50vw
    background-repeat: no-repeat

  &::after
    left: -100px
    background-image: url('/assets/images/plant3.svg')
    transform: rotate(45deg) scaleX(-1)

  &::before
    right: -50px
    background-image: url('/assets/images/plant1.svg')
    transform: rotate(-45deg) scaleX(-1)

  &__title
    margin-bottom: 118px
    padding-left: 9vw
    max-width: 800px
  
  &__content
    max-width: 325px
    align-self: center

  &__img
    margin-top: 45px
    max-width: 300px
    border-radius: 50%
    border: 5px double $black

  &__name, &__text
    margin-bottom: 45px
  
  &__text
    max-width: 305px
  
  @media only screen and (max-width: $phone)
    margin-bottom: 20px
    padding-right: 30px
    padding-left: 30px
    padding-bottom: 0

    &::before, &::after
      top: 53%
      width: 53vw
      height: 83vw
    
    &::after
      left: -78px

    &__title
      margin-bottom: 80px
      padding-left: 0
    
    &__content
      width: 100%
      max-width: 100%
    
    &__img
      width: 258px
      margin: 215px auto 0
