.relative
  position: relative

.absolute
  position: absolute

.fixed
  position: fixed

.top0
  top: 0

.right0
  right: 0

.bottom0
  bottom: 0

.left0
  left: 0

.flex
  display: flex

.dir-column
  flex-direction: column

.align-center
  align-items: center

.align-start
  align-items: flex-start

.justify-center
  justify-content: center

.mb-50
  margin-bottom: 50px

.mb-20
  margin-bottom: 20px

.mt-80
  margin-top: 80px

.footer-padding
  padding-right: 9vw
  padding-left: 9vw
