@import './../../../styles/variables'

.partners
  max-width: 100vw
  margin-right: auto
  margin-left: auto
  padding-bottom: 100px
  justify-content: center
  overflow-y: scroll
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none

  &__item
    width: 250px
    padding-right: 20px
    padding-left: 20px
  
  @media only screen and (max-width: '1199px')
    justify-content: flex-start

  @media only screen and (max-width: $phone)
    padding-bottom: 50px
