//COLORS
$black: #000
$light: #fff
$dark: #03334e
$primary: #25ABAA
$secondary: #7FD9BA
$highlight: #C0FEC8
$primary-gradient: linear-gradient($primary, $secondary)
$primary-gradient-2: linear-gradient($primary, $highlight)
$primary-gradient-h: linear-gradient(-90deg, $secondary, $primary)

//DEVICES
$tablet: 959px
$phone: 639px
