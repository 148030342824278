@import './styles/reset'
@import './styles/fonts'
@import './styles/variables'

@import './styles/layout'

body
  color: $dark
  font-family: 'Tisa Sans'
  font-size: 18px
  line-height: 26px

.pointer
  cursor: pointer

.text-light
  color: $light

.headline
  font-family: 'Playfair Display', serif

.h1
  font-size: min(63px, max(4.375vw, 35px))
  line-height: 1.25

.h2
  font-size: min(50px, max(3.48vw, 35px))
  line-height: 1

.gradient-bg
  background: $primary-gradient

.gradient-bg-2
  background: $primary-gradient-2

.gradient-bg-h
  background: $primary-gradient-h

.dark-bg
  background-color: $dark

.text-left
  text-align: left
